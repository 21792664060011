import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import Widgets from '../components/widgets/Widgets' 
import Seo from 'gatsby-plugin-wpgraphql-seo';
import HubspotForm from 'react-hubspot-form'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function WebinarPage({ data }) {
  const page = data.allWpPage.nodes[0];
  const form = page.webinar?.form;
  const speakers = page.webinar?.speakers;

  // get first paragraph
  const matches = [];
  page.content.replace(/<p>(.*?)<\/p>/g, function () {
    //use arguments[0] if you need to keep <p></p> html tags
    matches.push(arguments[0]);
  });

  const firstParagraph = (matches.length && page.content.indexOf(matches[0])===1) ? matches[0] : "";
  const content = (firstParagraph==='') ? page.content : page.content.replace(firstParagraph, '');

  return (
    <Layout className="page-webinar">	
      <Seo post={page} />
	  <article>
	    <header className="page-widgets">
	      <Hero widget={{layout : 'mini'}} />
	    </header>
		<div className="internal columns">
			<div className="intro mobile-intro">
				<h1>{page.title}</h1>
				
				<div className="landbay-article-styles" dangerouslySetInnerHTML={{ __html: firstParagraph }} />
			</div>
			<aside>
				<div className="sticky">
				  {form?.title && (
				    <h2>{form.title}</h2>
				  )}		    	
			
				  {form?.details && (
				    <div className="details" dangerouslySetInnerHTML={{ __html: form.details }} />
				  )}
				
				  {form?.hubspotRegion && form?.hubspotFormPortalId && form?.hubspotFormId && (
					<HubspotForm
					  region={form.hubspotRegion}
					  portalId={form.hubspotFormPortalId}
					  formId={form.hubspotFormId}
				    />
				  )}
				</div>
			</aside>
			
			<div className="content">
				<div className="intro desktop-intro">
				    <h1>{page.title}</h1>
					
				    <div className="landbay-article-styles" dangerouslySetInnerHTML={{ __html: firstParagraph }} />
				</div>
				
				<div className="landbay-article-styles" dangerouslySetInnerHTML={{ __html: content }} />
				
				{speakers?.length>0 && (
				  <div className="speakers">
					{speakers.map((speaker, i) => (
					  <div className="speaker" key={`speaker${i}`}>
						<h4>
						  <GatsbyImage image={getImage(speaker.image.localFile)} alt={speaker.name} />
						  {speaker.name}
						</h4>
						<div className="bio" dangerouslySetInnerHTML={{ __html: speaker.bio }} />
					  </div>
					))}
				  </div>
				)}
			</div>
		</div>
		
		<Widgets widgets={page.widgets?.pageWidgets} />
		
	  </article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
		slug
	    content
	    webinar {
		  form {
		    title	
			details
			hubspotRegion
			hubspotFormPortalId
			hubspotFormId
		  }
		  speakers {
			image {
              localFile {
		        childImageSharp {
	              gatsbyImageData(width: 128, height: 128)
	            }
              }
            }
			name
			bio
		  }
		}
        ...PageSEOFragment
	    
		...WidgetFragment
      }
    }
  
  }
`